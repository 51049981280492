import React from "react";
import { Tabs, Tab } from "react-bootstrap";

import Mobile1 from "../../../images/home/mobile1.png";
import Mobile2 from "../../../images/home/mobile2.png";

import SearchIcon from "../../../images/svg/SearchIcon.svg";
import ScholarshipIcon from "../../../images/svg/ScholarshipIcon.svg";
import BagIcon from "../../../images/svg/BagIcon.svg";

const GuideLine = () => {
  return (
    <Tabs defaultActiveKey="purchase" id="uncontrolled-tab-example">
      <Tab eventKey="purchase" title="Покупка">
        {/**section 1 */}
        <div className="row">
          <div className="col-md-6 mb-3 mb-md-0">
            <div className="image">
              <img src={Mobile1} alt="Интернет магазин брендовой одежды Posh Market - легкая покупка через удобное мобильное приложение." />
            </div>
          </div>
          <div className="col-md-6">
            <div className="text-content">
              <div className="icon-wrap">
                <img src={SearchIcon} alt="guideline-2" />
                <span>1</span>
              </div>
              <div className="icon-content">
                <h4>Найдите интересующий вас товар</h4>
                <p>
                  Тысячи наименований новой, или в отличном состоянии брендовой
                  одежды, обуви и аксессуаров со скидками до 90% в мобильном
                  приложении POSH MARKET
                </p>
              </div>
            </div>
            <div className="text-content">
              <div className="icon-wrap">
                <img src={ScholarshipIcon} alt="" />
                <span>2</span>
              </div>
              <div className="icon-content">
                <h4>Безопасная сделка и проверка подлинности</h4>
                <p>
                  Все товары дороже 20 000 руб. проходят бесплатную проверку
                  подлинности. Для других товаров услугу можно заказать отдельно
                </p>
              </div>
            </div>
            <div className="text-content">
              <div className="icon-wrap">
                <img src={BagIcon} alt="" />
                <span>3</span>
              </div>
              <div className="icon-content">
                <h4>Доставка лично в руки</h4>
                <p>Интегрированная служба доставки СДЭК</p>
              </div>
            </div>
          </div>
        </div>
      </Tab>

      <Tab eventKey="sell" title="Продажа">
        {/**section 1 */}
        <div className="row">
          <div className="col-md-6 mb-3 mb-md-0">
            <div className="image">
              <img src={Mobile2} alt="Posh Market - сток интернет магазин брендовой одежды для быстрой продажи брендовых вещей" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="text-content">
              <div className="icon-wrap">
                <img src={SearchIcon} alt="" />
                <span>1</span>
              </div>
              <div className="icon-content">
                <h4>Опубликуйте объявление</h4>
                <p>Разместите объявление о продаже за 15 секунд</p>
              </div>
            </div>
            <div className="text-content">
              <div className="icon-wrap">
                <img src={ScholarshipIcon} alt="" />
                <span>2</span>
              </div>
              <div className="icon-content">
                <h4>Безопасная сделка</h4>
                <p>
                  После оплаты товара покупателем, вызовите курьера из
                  приложения
                </p>
              </div>
            </div>
            <div className="text-content">
              <div className="icon-wrap">
                <img src={BagIcon} alt="" />
                <span>3</span>
              </div>
              <div className="icon-content">
                <h4>Получите деньги на карту</h4>
                <p>Выплата производится моментально</p>
              </div>
            </div>
          </div>
        </div>
      </Tab>
    </Tabs>
  );
};
export default GuideLine;
