import React, { Component } from "react";
import Img from "react-cool-img";
import { withRouter } from "react-router-dom";
import Carousel from "react-multi-carousel";
import { connect } from "react-redux";
import {
  clearProducts,
  getPremiumProductList,
  resetAllState,
  openModal,
} from "../../store/actions";
import "./home.scss";
import image1 from "../../images/home/home-banner-left-new.jpg";
import image2 from "../../images/home/home-banner-right.jpg";
import MobilePreview from "../../images/home/mobile-preview.png";

import SearchIcon from "../../images/svg/About-Search.svg";
import PhoneIcon from "../../images/svg/About-Phone.svg";
import ShopIcon from "../../images/svg/About-Shop.svg";

import LoadingImage from "../../images/loading-7.png";
import NoImage from "../../images/no-image.png";
import GuideLine from "./GuideLine";
import Loader from "../Loader";
import { PREMIUM_BRANDS, PREMIUM_BAG_SHOES } from "../../global/config";
import _ from "lodash";
import { Helmet } from "react-helmet";
import { META_DATA } from "../../global/config";
import PremiumIMG from "../../images/home/premium.png";
import BagIMG from "../../images/home/bag.png";
import SneakerIMG from "../../images/home/sneaker.png";
import HeelIMG from "../../images/home/heel.png";
import { Toast } from "react-bootstrap";
import AppDownload from "../AppDownload";
import { QrBanner } from "../../shared/components/qr-banner/qr-banner";

const CookieBox = ({ setShow, show }) => {
  return (
    <Toast show={show}>
      <Toast.Body>
        <span>
          Мы используем куки для наилучшего представления нашего сайта.
          Продолжая использовать сайт, вы подтверждаете свое согласие на
          использование файлов cookie и других подобных технологий.
        </span>
        <button className="load-more-btn border-btn" onClick={() => setShow()}>
          Ok
        </button>
      </Toast.Body>
    </Toast>
  );
};

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultPageSize: 10,
      topCategory: [
        {
          image: PremiumIMG,
          text: "Премиум", //"Premium"
          link: "/women/premium",
        },
        {
          image: BagIMG,
          text: "Сумки", //"Bags",
          link: "/women/bags",
        },
        {
          image: SneakerIMG,
          text: "Кроссовки", //"Sneackers",
          link: "/women/sneackers",
        },
        {
          image: HeelIMG,
          text: "Туфли", //"Heels",
          link: "/women/heels",
        },
      ],
      loadCount: 1,
      showAlert: false,
    };
  }

  componentDidMount() {
    const payload = {
      offset: (this.state.defaultPageSize * (1 - 1)).toString(),
      // limit: this.state.defaultPageSize,
      user_id: "",
      category_ids: PREMIUM_BAG_SHOES,
      size_ids: "",
      brand_ids: PREMIUM_BRANDS,
      color_ids: "",
      product_use_status_ids: "", // 0 - new with tag
      low_price: 10000,
      high_price: 0,
      sorting_options: "",
      is_web_request: 1,
    };
    this.props.dispatch(resetAllState());
    this.props.dispatch(clearProducts());
    this.props.dispatch(getPremiumProductList(payload, false));
    // this.props.dispatch(openModal("payload"));

    if (
      _.find(document.cookie.split("="), function (o) {
        return o.indexOf("cookiename") > -1;
      })
    ) {
      this.setState({ showAlert: false });
    } else {
      this.setState({ showAlert: true });
    }
  }

  loadMoreProducts = () => {
    let { defaultPageSize, loadCount } = this.state;
    this.setState(
      {
        loadCount: loadCount + 1,
      },
      () => {
        let skip = defaultPageSize * loadCount;
        const payload = {
          offset: skip,
          limit: this.state.defaultPageSize,
          user_id: "",
          category_ids: "",
          size_ids: "",
          brand_ids: PREMIUM_BRANDS,
          color_ids: "",
          product_use_status_ids: "", // 0 - new with tag
          low_price: 0,
          high_price: 0, //20000,
          sorting_options: 1,
          is_web_request: 1,
        };
        this.props.dispatch(getPremiumProductList(payload, true));
      }
    );
  };

  navigate = (product) => {
    let product_cat_eng = "";
    switch (product.category_name.toLowerCase().trim()) {
      case "одежда":
        product_cat_eng = "clothes";
        break;
      case "обувь":
        product_cat_eng = "shoes";
        break;
      case "сумки":
        product_cat_eng = "bags";
        break;
      case "аксессуары":
        product_cat_eng = "accessories";
        break;
      case "косметика и духи":
        product_cat_eng = "perfume and make up";
        break;

      default:
        product_cat_eng = product.category_name;
        break;
    }

    let main_cat = _.kebabCase(
      `${product.mainCatType}-${product_cat_eng}`
    ).toLowerCase();
    let sub_cat = _.kebabCase(`${product.sub_category_name_eng}`).toLowerCase();
    let b_name = _.kebabCase(`${product.brand_name_eng}`).toLowerCase();
    let productId = product.product_id;
    let color = _.kebabCase(
      `${product.color_name_eng}-${product.sub_category_name_eng}-${productId}`
    ).toLowerCase();
    this.props.history.push(`/${main_cat}/${sub_cat}/${b_name}/${color}`);
  };

  renderTopCategory = (data, i) => {
    return (
      <div className="col-6 col-md-3 text-center" key={i}>
        <div
          className="cat-list"
          onClick={() => this.props.history.push(data.link)}
        >
          <div className="cat-image">
            <img src={data.image} alt="category" />
          </div>
          <span>{data.text}</span>
        </div>
      </div>
    );
  };

  renderProduct = (product, i) => {
    return (
      <div className="col-6 col-sm-6 col-md-4 col-xl-2" key={i}>
        <div
          className="product-box"
          onClick={() => {
            this.navigate(product);
          }}
        >
          <div className="product-image">
            <Img
              placeholder={LoadingImage}
              src={product.product_list_url.split(",")[0]}
              error={NoImage}
              alt="product-image"
              className="img-fluid"
            />
          </div>
          <div className="product-info">
            <p title={product.brand_name}>
              {product.brand_name.length > 13
                ? `${product.brand_name.substring(0, 11)}...`
                : product.brand_name}
            </p>
            <p className="catname-size">
              <span className="elipsis" title={product.sub_category_name}>
                {product.sub_category_name}
              </span>
              {product.sizename !== "AllSizes" && (
                <span className="ml-auto">
                  {product.sizename !== "AllSizes"
                    ? `Size : ${product.sizename}`
                    : null}
                </span>
              )}
            </p>
            <p>
              <del>P</del>
              {product.sell_for}{" "}
              {product.bought_for > 0 && (
                <span>
                  {product.bought_for}
                  <del>P</del>
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
    );
  };

  dissMissAlert = () => {
    this.setState({
      showAlert: false,
    });

    var d = new Date();
    d.setTime(d.getTime() + 60 * 1000 * 60 * 24 * 30 * 12); // 1 year
    var expires = "expires=" + d.toUTCString();
    document.cookie = "cookiename=yes;" + expires + ";path=/";
  };

  render() {
    const { premiumProducts, isLoading } = this.props;
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };

    return (
      <>
        <Helmet>
          <link rel="canonical" href={window.location.href} />
          <meta property="og:url" content={window.location.href} />
          <meta
            name="google-site-verification"
            content={META_DATA.home.description2}
          />
          <meta name="description" content={META_DATA.home.description} />
          <title>{META_DATA.home.title}</title>
        </Helmet>
        <section className="main-home">
          <div className="container">
            <div className="row top-category">
              <div className="col-12">
                <Carousel
                  responsive={responsive}
                  swipeable={true}
                  draggable={true}
                  showDots={false}
                  // infinite={true}
                  autoPlay={this.props.deviceType === "mobile" ? true : false}
                  // autoPlaySpeed={1000}
                  keyBoardControl={true}
                  customTransition="all .5"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "desktop", "mobile"]}
                  deviceType={this.props.deviceType}
                  // dotListClass="custom-dot-list-style"
                  // itemClass="carousel-item-padding-10-px"
                >
                  <div style={{ marginRight: "5px" }}>
                    <img
                      src={image1}
                      alt="home-banner-1"
                      className="img-fluid"
                    />
                  </div>
                  <div style={{ marginLeft: "5px" }}>
                    <img
                      src={image2}
                      alt="home-banner-2"
                      className="img-fluid"
                    />
                  </div>
                </Carousel>
              </div>
            </div>
            <div className="row catlist-wrapper">
              {this.state.topCategory.map((data, index) =>
                this.renderTopCategory(data, index)
              )}
            </div>
            <section className="product-section">
              <div className="container">
                <div className="product-list">
                  {/* <h2>Sustainable brands</h2> */}
                  <p className="popular-brands">Новые поступления</p>
                  <div className="row gx-2">
                    {premiumProducts &&
                      premiumProducts.length > 0 &&
                      _.uniqBy(premiumProducts, (obj) =>
                        [_.get(obj, "product_id", "")].join()
                      )
                        .slice(0, 10)
                        .map((product, index) =>
                          this.renderProduct(product, index)
                        )}
                    {isLoading && <Loader />}
                  </div>
                  <div className="text-center">
                    <button
                      className="load-more-btn border-btn"
                      // onClick={() => this.loadMoreProducts()}
                      onClick={() => this.props.history.push("/women")}
                    >
                      Посмотреть больше{" "}
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
        <div className="seperator">
          <div className="container">
            <hr></hr>
          </div>
        </div>
        {/* New Design */}
        <section className="about-section section">
          <div className="container-fluid">
            <h1 className="n-section-title text-center">
              <strong>POSH MARKET</strong> - ресейл платформа #1
            </h1>
            <div className="n-about-posh">
              <div className="row">
                <div className="col-md-4 mb-3 mb-md-0">
                  <div className="bg-wrap">
                    <div className="top-div">
                      <div className="icon">
                        <img src={SearchIcon} alt="search" />
                      </div>
                      <h2>
                        Проверка
                        <br />
                        подлинности товаров
                      </h2>
                    </div>
                    <p>
                      Покупая на POSH MARKET, вы можете быть уверенными в
                      подлинности товара. Проверка осуществляется экспертами
                      мирового уровня и сервисом{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.entrupy.com"
                        className="orange-txt-clr"
                      >
                        entrupy.com
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-md-4 mb-3 mb-md-0">
                  <div className="bg-wrap">
                    <div className="top-div">
                      <div className="icon">
                        <img src={PhoneIcon} alt="phone" />
                      </div>
                      <h2>
                        Безопасная
                        <br />
                        сделка
                      </h2>
                    </div>
                    <p>
                      После оплаты денежные средства покупателя хранятся в банке
                      Тинькофф до завершения сделки. Продавец мгновенно получает
                      деньги на карту после закрытия сделки покупателем.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="bg-wrap">
                    <div className="top-div">
                      <div className="icon">
                        <img src={ShopIcon} alt="shop" />
                      </div>
                      <h2>Доставка</h2>
                    </div>
                    <p>
                      Доставка от продавца до покупателя по России занимает 1-3
                      рабочих дня. Все товары застрахованы во время доставки.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <QrBanner
          title="POSH MARKET"
          description="Купить или продать товар на POSH MARKET можно через наше
          мобильное приложение. Скачайте его сейчас!"
          className="checkout__qr-banner"
        />
        <div className="seperator">
          <div className="container">
            <hr></hr>
          </div>
        </div>
        <section className="guidline-section section">
          <div className="container">
            <h3 className="section-title text-center">
              Покупайте и продавайте брендовую одежду <br /> через удобное
              мобильное приложение
            </h3>
            <p className="sub-para">
              В приложении POSH MARKET на Android и iOS вы сможете совершать
              покупку и продажу вещей в несколько простых шагов
            </p>
            <div className="guideline">
              <GuideLine />
            </div>
          </div>
          <div className="cookie-box">
            <CookieBox
              setShow={() => this.dissMissAlert()}
              show={this.state.showAlert}
            />
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = ({ home }) => {
  return {
    isLoading: home.loading,
    premiumProducts: home.premiumProductsList,
    total: home.total,
  };
};

export default connect(mapStateToProps)(withRouter(Home));
